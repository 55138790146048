import { TweekProvider } from '@home-mgmt-shared/tweek-helpers';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/index.css';
import { RoutePrefix } from './models';
import { Routes } from './routes';
import { initializeLogger, initializeWixiSdk, initializeWixiSdkWithAuth } from './utils';

type AppComponentProps = {
    routePrefix?: RoutePrefix;
};

const AppComponent = ({ routePrefix }: AppComponentProps) => {
    useEffect(() => {
        if (!IS_MX_APP) {
            initializeWixiSdk();
            return;
        }

        const initWixiSdk = async () => {
            await initializeWixiSdkWithAuth();
        };

        initWixiSdk();
        initializeLogger();
    }, []);
    const base = routePrefix && routePrefix.prefix ? routePrefix.prefix.replace('/', '') : '';
    return (
        <TweekProvider>
            <BrowserRouter basename={base}>
                <Routes />
            </BrowserRouter>
        </TweekProvider>
    );
};

export const App = AppComponent;
