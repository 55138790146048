import { Theme } from '@emotion/react';

export const appScanTheme: Theme = {
    font: {
        type: 'VerizonNHGTX',
        bold: 'VerizonNHGTX',
        accentColor: '#000000',
    },
    accentColors: {
        primary: '#DB1B1B',
        secondary: '#000000',
    },
    button: {
        primaryColor: '#000000',
        selectedColor: '#DB1B1B',
        disabledColor: '#6E767D',
        disabledTextColor: '#000000',
        defaultTextColor: '#000000',
    },
    special: {
        primaryColor: '#000000',
        secondaryColor: '#000000',
    },
    spinner: {
        primaryColor: '#DB1B1B',
    },
    insights: {
        carouselScrollButtonColor: '#00000000',
        carouselScrollButtonActiveColor: '#00000023',
        carouselActiveDotColor: '#000000',
        carouselInactiveDotColor: '#ffffff',
        carouselDotBorderColor: '#000000',
        buttonHighlightColor: '#00000000',
        buttonColor: '#000000',
        buttonTextColor: '#ffffff',
    },
};
