// homepage constants
import { config } from './config';

export const DOWNLOAD_HEADER = 'Download speed';
export const UPLOAD_HEADER = 'Upload speed';
export const UNITS_MBPS = 'Mbps';
export const NETWORK_CHECKUP_HEADER = 'Wi-Fi checkup results';
export const NETWORK_CHECKUP_SUBHEADER = 'Your Wi-Fi speeds';
export const STREAMING_STATUS_HEADER = 'Your connection to popular sites';
export const STREAMING_SUBHEADER = 'We checked the speed of your connection to these sites';
export const APPLICATION_ID = 'applicationId';
export const AUTH_TOKEN = 'authToken';
export const USER_ID = 'userId';
export const SUB_ID = 'subscriberId';
export const ACC_ID = 'accountId';
export const FLOW_ID = 'flow';
export const CID = 'cid';
export const PROGRAM = 'program';
export const IS_TEST = 'isTest';
export const USER_BROWSER_ID = 'userBrowserId';
export const PLATFORM = 'platform';

// error page constants
export const ERROR_SUBHEADER = 'Something went wrong when we were getting your results';
export const TRY_AGAIN = 'Try again';

export const EXPERT_PHONE_NUMBER = '844-769-1991';

// app constants
export const PARTNER = 'vzw';
export const APP_NAME = 'vzw-dashboard';

export const isProduction = (): boolean => config.env === 'prod';

// default tweek values
export const DEFAULT_FEEDBACK_TAGS = {
    maxScoreTagList: ['Fast results', 'Easy to use', 'Easy to understand', 'Fixed my issue', 'Learned something new'],
    lowScoreTagList: [
        'Make it faster',
        'Make it easier to use',
        'Make it easier to understand',
        'Fix my issue',
        'Teach me something new',
    ],
};

export const DEFAULT_STREAMING_GUIDELINES = [
    { capability: 'Email', downloadReq: 1, uploadReq: 0 },
    { capability: 'Browse the web', downloadReq: 3, uploadReq: 0 },
    { capability: 'Game online', downloadReq: 5, uploadReq: 0 },
    { capability: 'Video chat', downloadReq: 1, uploadReq: 1 },
    { capability: 'Video conference', downloadReq: 5, uploadReq: 2 },
    {
        capability: 'Stream HD video on a single device',
        downloadReq: 5,
        uploadReq: 0,
    },
    {
        capability: 'Stream HD video on multiple devices',
        downloadReq: 10,
        uploadReq: 0,
    },
    {
        capability: 'Stream 4k video on a single device',
        downloadReq: 25,
        uploadReq: 0,
    },
];
