import * as React from 'react';

export const Switch = () => (
    <svg height="100%" width="100%" viewBox="0 0 84 130" fill="none" role="img" aria-label="switch">
        <path d="M.368 0v130h83.263V0H.368zm80.317 127.098H3.358V2.924h77.327v124.174z" fill="#010101" />
        <path d="M17.38 19.709v92.078h49.24V19.708H17.38zm46.25 89.132H20.348V22.655H63.63v86.186z" fill="#010101" />
        <path
            d="M39.664 70.456v7.243H36.03v4.63a1.484 1.484 0 01-1.484 1.461 1.462 1.462 0 01-1.462-1.462V77.7a3.632 3.632 0 110-7.243V46.223a1.462 1.462 0 011.462-1.44 1.484 1.484 0 011.484 1.44v24.233h3.633zM50.628 46.225v14.132H47.66V46.225a1.484 1.484 0 012.946 0h.022zM54.304 63.968a3.631 3.631 0 01-3.655 3.61v14.753a1.462 1.462 0 01-1.462 1.462 1.484 1.484 0 01-1.484-1.462V67.579h-3.677v-7.221h6.645a3.632 3.632 0 013.633 3.61zM41.989 122.594c1.529 0 2.768-1.229 2.768-2.746s-1.24-2.747-2.768-2.747c-1.53 0-2.77 1.23-2.77 2.747s1.24 2.746 2.77 2.746zM44.757 10.296a2.769 2.769 0 11-5.537-.089 2.769 2.769 0 015.537.089z"
            fill="#010101"
        />
    </svg>
);
