import * as React from 'react';

export const Multimedia = () => (
    <svg height="100%" width="100%" viewBox="0 0 140 110" fill="none" role="img" aria-label="multimedia">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.391 105.978h75.326c1.218 0 2.283-1.065 2.283-2.282V2.956c0-1.217-1.065-2.282-2.283-2.282H5.478c-1.217 0-2.282 1.065-2.282 2.282v43.979c0 1.217 1.065 2.282 2.282 2.282 1.37 0 2.283-.913 2.283-2.282V5.239h127.674v96.174H62.391c-1.217 0-2.282 1.065-2.282 2.283 0 1.369.913 2.282 2.282 2.282zm-21.608-1.522c0 1.218 1.065 2.283 2.283 2.283 1.37 0 2.282-.913 2.282-2.283 0-22.37-18.108-40.478-40.478-40.478-1.217 0-2.283 1.065-2.283 2.283 0 1.37.913 2.282 2.283 2.282 19.783 0 35.913 16.13 35.913 35.913zm-13.239 0c0-12.478-10.196-22.674-22.674-22.674-1.217 0-2.283 1.066-2.283 2.283 0 1.217 1.066 2.283 2.283 2.283a18.055 18.055 0 0118.109 18.108c0 1.218 1.065 2.283 2.282 2.283 1.218 0 2.283-1.065 2.283-2.283zM4.87 99.587c2.739 0 4.87 2.131 4.87 4.87 0 2.739-2.131 4.869-4.87 4.869-2.74 0-4.87-2.13-4.87-4.869s2.13-4.87 4.87-4.87zM60.48 72.38V33.62L93.706 53 60.481 72.38zM84.904 53L64.915 41.34v23.32L84.903 53z"
            fill="#000"
        />
    </svg>
);
