import * as React from 'react';

export const Speaker = () => (
    <svg height="100%" width="100%" viewBox="0 0 52 52" fill="none" role="img" aria-label="speaker">
        <path
            clipRule="evenodd"
            d="M26.186 23.293c7.787 0 14.1-2.09 14.1-4.668 0-2.578-6.313-4.668-14.1-4.668s-14.1 2.09-14.1 4.668c0 2.578 6.313 4.668 14.1 4.668z"
            stroke="#000"
            strokeWidth={1.12}
        />
        <path
            clipRule="evenodd"
            d="M26.493 18.936c3.893 0 7.05-.697 7.05-1.556 0-.86-3.157-1.556-7.05-1.556-3.894 0-7.05.697-7.05 1.556 0 .86 3.156 1.556 7.05 1.556z"
            stroke="#000"
            strokeWidth={1.12}
        />
        <path
            d="M40.899 18.936s1.57 3.765 1.57 8.043c0 4.348-1.57 7.344-1.57 7.344-.817 1.733-6.548 3.91-14.713 3.91s-12.467-1.38-14.1-3.113c0 0-2.638-4.455-2.638-8.14 0-3.687 2.026-8.044 2.026-8.044"
            stroke="#000"
            strokeWidth={1.75}
        />
    </svg>
);
