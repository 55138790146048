import * as React from 'react';

export const Scanner = () => (
    <svg height="100%" width="100%" viewBox="0 0 116 130" fill="none" role="img" aria-label="scanner">
        <path
            d="M102.122 98.485L89.712 65c-.197-.788-.984-1.379-1.772-1.379H28.258c-.788 0-1.576.591-1.773 1.379L13.682 98.879c-.197.59-.197 1.181.197 1.772s.985.788 1.576.788h84.894c1.182 0 1.969-.788 1.969-1.97.197-.393 0-.787-.196-.984zM18.409 97.5l11.228-29.94H86.56L97.788 97.5H18.41z"
            fill="#000"
        />
        <path
            d="M.879 108.333v11.819c0 5.515 4.333 9.848 9.848 9.848h94.546c5.515 0 9.848-4.333 9.848-9.848V108.53c0-.394 0-.788-.197-1.182L96.212 56.924l16.94-54.363c.197-.591 0-1.182-.197-1.773-.394-.394-.985-.788-1.576-.788H4.819c-.789 0-1.38.394-1.773.788-.197.59-.394 1.182-.197 1.773l17.136 54.166-18.91 51.015c-.196.197-.196.394-.196.591zm6.5-104.394H108.62L92.667 54.758H23.333L7.38 3.939zm15.954 54.758h69.334l17.727 47.667H5.606l17.727-47.667zM4.819 110.303h106.364v5.909h-8.273c-1.182 0-1.969.788-1.969 1.97s.787 1.97 1.969 1.97h8.273c0 3.348-2.561 5.909-5.909 5.909H10.727c-3.348 0-5.909-2.561-5.909-5.909h8.273c1.182 0 1.97-.788 1.97-1.97s-.788-1.97-1.97-1.97H4.818v-5.909z"
            fill="#000"
        />
        <path
            d="M25.895 124.091c3.349 0 5.91-2.561 5.91-5.909 0-3.349-2.561-5.91-5.91-5.91-3.348 0-5.909 2.561-5.909 5.91 0 3.348 2.56 5.909 5.91 5.909zm0-7.879c1.182 0 1.97.788 1.97 1.97 0 1.181-.788 1.969-1.97 1.969-1.181 0-1.97-.788-1.97-1.969 0-1.182.789-1.97 1.97-1.97zM40.668 124.091c3.348 0 5.909-2.561 5.909-5.909 0-3.349-2.56-5.91-5.91-5.91-3.348 0-5.908 2.561-5.908 5.91 0 3.348 2.757 5.909 5.909 5.909zm0-7.879c1.182 0 1.97.788 1.97 1.97 0 1.181-.788 1.969-1.97 1.969s-1.97-.788-1.97-1.969c0-1.182.985-1.97 1.97-1.97z"
            fill="#000"
        />
    </svg>
);
