import {
    Computer,
    FitnessBands,
    GameConsole,
    Lighting,
    Mp3Player,
    Multimedia,
    NasDisk,
    Phone,
    Printer,
    Router,
    Scanner,
    SmartSpeaker,
    Speaker,
    Switch,
    Tablet,
    Television,
} from '@home-mgmt-shared/common-ui';

export interface DeviceTypeInfo {
    icon: JSX.Element;
    prettyKind: string;
}

export const deviceTypeMapping: Record<string, DeviceTypeInfo> = {
    computer: { icon: Computer(), prettyKind: 'Computer' },
    game_console: { icon: GameConsole(), prettyKind: 'Game Console' },
    fitness_bands: { icon: FitnessBands(), prettyKind: 'Smart Watch' },
    lighting: { icon: Lighting(), prettyKind: 'Lighting' },
    mp3_player: { icon: Mp3Player(), prettyKind: 'MP3 Player' },
    multimedia: { icon: Multimedia(), prettyKind: 'Multimedia' },
    nas: { icon: NasDisk(), prettyKind: 'Nas' },
    nas_disk: { icon: NasDisk(), prettyKind: 'Nas Disk' },
    phone: { icon: Phone(), prettyKind: 'Phone' },
    printer: { icon: Printer(), prettyKind: 'Printer' },
    router: { icon: Router(), prettyKind: 'Router' },
    scanner: { icon: Scanner(), prettyKind: 'Scanner' },
    smart_speaker: { icon: SmartSpeaker(), prettyKind: 'Smart Speaker' },
    speaker: { icon: Speaker(), prettyKind: 'Speaker' },
    switch: { icon: Switch(), prettyKind: 'Switch' },
    tablet: { icon: Tablet(), prettyKind: 'Tablet' },
    tv: { icon: Television(), prettyKind: 'TV' },
    watch: { icon: FitnessBands(), prettyKind: 'Watch' },
};
