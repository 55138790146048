import * as React from 'react';

export const Computer = (): JSX.Element => (
    <svg height="100%" width="100%" viewBox="0 0 140 96" fill="none" role="img" aria-label="computer">
        <path
            d="M138.485 80.265h-6.94V10.332a10.314 10.314 0 00-3.054-7.3A10.468 10.468 0 00121.135 0H18.847a10.467 10.467 0 00-7.355 3.031 10.311 10.311 0 00-3.055 7.301v69.933H1.48c-.394.005-.77.163-1.048.442A1.48 1.48 0 000 81.75v2.955a11.27 11.27 0 003.344 7.987A11.44 11.44 0 0011.397 96h117.206c3.02 0 5.916-1.19 8.053-3.307A11.27 11.27 0 00140 84.706V81.75a1.478 1.478 0 00-.936-1.378 1.513 1.513 0 00-.579-.108zM11.415 10.332a7.35 7.35 0 012.176-5.216 7.462 7.462 0 015.257-2.161h102.287a7.482 7.482 0 012.859.553c.907.37 1.732.914 2.426 1.601a7.366 7.366 0 012.183 5.241v69.933h-4.544V9.319a1.466 1.466 0 00-.914-1.357 1.483 1.483 0 00-.566-.112H17.403a1.472 1.472 0 00-1.051.425 1.45 1.45 0 00-.428 1.044v70.946h-4.51V10.332zm38.751 72.87h39.686V86.7H50.113l.053-3.497zm41.183-2.937H18.883v-69.46h102.164v69.46H91.349zm45.657 4.44a8.345 8.345 0 01-2.465 5.899 8.472 8.472 0 01-5.938 2.459H11.397a8.47 8.47 0 01-5.949-2.453 8.343 8.343 0 01-2.471-5.904V83.22h44.16v4.913c0 .39.155.766.432 1.044s.653.437 1.047.442h42.733c.397 0 .778-.157 1.058-.436a1.48 1.48 0 00.439-1.05V83.22h44.16v1.486z"
            fill="#000"
        />
    </svg>
);
