import * as React from 'react';

export const Phone = () => (
    <svg height="100%" width="100%" viewBox="0 0 19 33" fill="none" role="img" aria-label="phone">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.089.33H3.895C2.27.33.992 1.563.992 3.135v26.386c0 1.572 1.278 2.807 2.903 2.807H16.09c1.626 0 2.903-1.235 2.903-2.807V3.136c0-1.572-1.277-2.807-2.903-2.807zm1.742 29.192c0 .955-.755 1.684-1.742 1.684H3.895c-.987 0-1.742-.73-1.742-1.684V3.136c0-.954.755-1.684 1.742-1.684H16.09c.987 0 1.742.73 1.742 1.684v26.386zM10.108 3.136c.349 0 .581-.224.581-.561 0-.337-.232-.562-.58-.562-.35 0-.581.225-.581.562 0 .337.232.561.58.561zm1.336.562H8.54c-.349 0-.581.224-.581.56 0 .338.232.562.58.562h2.904c.348 0 .58-.224.58-.561 0-.337-.232-.561-.58-.561zM10.05 26.715h-.058c-1.103 0-1.974.842-1.974 1.909v.056c0 1.067.871 1.909 1.974 1.909h.058c1.103 0 1.974-.842 1.974-1.909v-.056c0-1.067-.87-1.909-1.974-1.909zm.813 2.021c0 .45-.348.786-.813.786h-.058c-.464 0-.813-.337-.813-.786v-.056c0-.45.349-.786.813-.786h.058c.465 0 .813.337.813.786v.056z"
            fill="#000"
        />
    </svg>
);
