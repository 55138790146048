import * as React from 'react';

export const Router = () => (
    <svg height="100%" width="100%" viewBox="0 0 34 24" fill="none" role="img" aria-label="router">
        <rect x={0.992} y={14.771} width={32} height={7.907} rx={2} stroke="#000" strokeWidth={1.5} />
        <path
            d="M6.02 14.376V1.7M12.42 14.376V1.7M5.564 19.516v-.79M9.22 19.516v-.79M12.878 19.516v-.79M16.535 19.516v-.79"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="square"
        />
    </svg>
);
