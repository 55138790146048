import * as React from 'react';

export const NasDisk = () => (
    <svg height="100%" width="100%" viewBox="0 0 72 130" fill="none" role="img" aria-label="nas disk">
        <path
            d="M63.269 33.925h-1.835v-7.066H55.07v-16.26a10.6 10.6 0 00-21.199 0v23.326H8.732A8.472 8.472 0 00.26 42.396v79.113A8.49 8.49 0 008.73 130H63.27a8.49 8.49 0 008.471-8.491V42.396a8.472 8.472 0 00-8.471-8.471zm-4.665 0h-9.877V29.67h9.877v4.255zM36.703 10.599a7.808 7.808 0 1115.615 0v16.26h-6.343v7.066h-9.272V10.599zM68.91 121.51a5.644 5.644 0 01-5.641 5.661H8.73a5.667 5.667 0 01-5.265-3.486 5.657 5.657 0 01-.434-2.175V42.396a5.68 5.68 0 015.64-5.66h25.2v36.033a7.808 7.808 0 11-15.615 0v-6.305h5.66V45.227H9.863v21.237h5.642v6.363a10.6 10.6 0 1021.198 0V36.736h26.566a5.661 5.661 0 015.64 5.66v79.113zM12.79 59.359h8.472v4.236H12.79v-4.236zm5.641-7.066v-4.255h2.83v8.49h-8.47v-8.49h2.81v4.255h2.83z"
            fill="#010101"
        />
        <path
            d="M9.864 120.107h28.967v-9.896H9.864v9.896zm2.928-7.066h23.209v4.177H12.792v-4.177zM38.831 103.144H9.864v2.83h28.967v-2.83z"
            fill="#010101"
        />
    </svg>
);
