import {
    retrieveItem,
    retrieveItemFromLocalStorage,
    storeItem,
    storeItemInLocalStorage,
} from '@home-mgmt-shared/common-services';
import { UserBrowser, USER_BROWSER_LOCAL_STORAGE_KEY_NAME } from '@home-mgmt-shared/common-ui';
import { initFullstory, setIdentityForFullStory } from '@home-mgmt-shared/fullstory';
import { updateTweekContext } from '@home-mgmt-shared/tweek-helpers';
import { analytics } from '@soluto-private/ns-analytics';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, RouteProps, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTweekValue } from 'react-tweek';
import { withThemeProviderWrapper } from '../providers';
import { appScanTheme, webScanTheme } from '../themes';
import {
    ACC_ID,
    APPLICATION_ID,
    APP_NAME,
    AUTH_TOKEN,
    CID,
    config,
    FLOW_ID,
    isProduction,
    IS_TEST,
    PARTNER,
    PLATFORM,
    PROGRAM,
    SUB_ID,
    USER_BROWSER_ID,
    USER_ID,
} from '../utils';

let AuthenticatedMXRoute: React.FC<RouteProps>;
if (IS_MX_APP) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires
    AuthenticatedMXRoute = require('@home-mgmt-shared/auth').AuthenticatedMXRoute;
}

const BandwidthCalculatorPage = lazy(() => import('../pages/BandwidthCalculatorPage'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const MultiScanPage = lazy(() => import('../pages/MultiScanPage'));
const OverviewPage = lazy(() => import('../pages/OverviewPage'));
const ScanResultsPage = lazy(() => import('../pages/ScanResultsPage'));
const GenericScanPage = lazy(() => import('../pages/GenericScanPage'));
const EmptyPage = lazy(() => import('../pages/EmptyPage'));

const useQueryParamRemover = (): boolean => {
    const [done, setDone] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const authToken = params.get(AUTH_TOKEN);

        if (authToken) {
            storeItem(AUTH_TOKEN, authToken);
            history.replace(location.pathname);
            setDone(true);
        }

        setDone(true);
    }, [history, location.pathname, location.search]);

    return done;
};

const initializeParamsAndAnalytics = (queryParams: string): void => {
    // applicationId is the clientId for fetching sessions + homegraph data for app scan
    // userId is the supplied userId (asurionId for now) for the web scan
    const params = new URLSearchParams(queryParams);
    const applicationId = params.get(APPLICATION_ID) ?? retrieveItem(APPLICATION_ID);
    const flowId = params.get(FLOW_ID) ?? retrieveItem(FLOW_ID);
    const cid = params.get(CID) ?? retrieveItem(CID);
    const program = params.get(PROGRAM) ?? retrieveItem(PROGRAM);
    const userId = params.get(USER_ID) ?? retrieveItem(USER_ID);
    const subscriberId = params.get(SUB_ID) ?? retrieveItem(SUB_ID);
    const accountId = params.get(ACC_ID) ?? retrieveItem(ACC_ID);
    const isTest = params.get(IS_TEST);
    const platform = params.get(PLATFORM);

    const userBrowserId =
        params.get(USER_BROWSER_ID) ??
        retrieveItemFromLocalStorage(USER_BROWSER_LOCAL_STORAGE_KEY_NAME) ??
        UserBrowser.Id;

    storeItem(APPLICATION_ID, applicationId);
    storeItem(FLOW_ID, flowId);
    storeItem(USER_ID, userId);
    storeItem(SUB_ID, subscriberId);
    storeItem(ACC_ID, accountId);
    storeItem(CID, cid);
    storeItem(PLATFORM, platform);
    storeItem(PROGRAM, program);
    storeItemInLocalStorage(USER_BROWSER_LOCAL_STORAGE_KEY_NAME, userBrowserId);

    analytics.setCustomParamValidator(() => true);
    updateTweekContext({
        partner: PARTNER,
        '@@id': userBrowserId,
        hostname: window.location.hostname,
        program,
        env: config.env,
    });

    analytics.updateProperties({
        browserId: userBrowserId,
        partner: PARTNER,
        appName: APP_NAME,
        userId: userId || applicationId,
        accountId,
        flow: flowId && flowId,
        subscriberId,
        isTest: !isProduction() || !!isTest,
        env: config.env,
        cid,
        program,
        isMX: IS_MX_APP,
    });

    initFullstory(!isProduction() || !!isTest);
    if (!isTest && isProduction()) {
        setIdentityForFullStory(userBrowserId, {
            partner_str: PARTNER,
            accountId_str: ACC_ID,
            cid_str: retrieveItem(CID) ?? '',
        });
    }
};

const Routes = () => {
    const location = useLocation();
    useEffect(() => {
        initializeParamsAndAnalytics(location.search);
    }, [location.search]);

    const canRoute = useQueryParamRemover();

    const routesEnabled =
        useTweekValue('network_scan/routes/overview', false) || (config.env !== 'production' && config.env !== 'prod');

    // do this while we are waiting to strip the query params to prevent remount routes
    if (!canRoute) {
        return null;
    }

    return (
        <Switch>
            <Suspense fallback={<div />}>
                {routesEnabled ? (
                    <>
                        <Route exact path="/error" component={withThemeProviderWrapper(ErrorPage, appScanTheme)} />
                        <Route
                            exact
                            path="/bandwidth"
                            component={withThemeProviderWrapper(BandwidthCalculatorPage, webScanTheme)}
                        />
                        <Route exact path="/scan" component={withThemeProviderWrapper(GenericScanPage, webScanTheme)} />
                        <Route
                            exact
                            path="/results"
                            component={withThemeProviderWrapper(ScanResultsPage, appScanTheme)}
                        />
                        <Route exact path="/signal" component={withThemeProviderWrapper(MultiScanPage, webScanTheme)} />
                        {IS_MX_APP && (
                            <AuthenticatedMXRoute
                                exact
                                path="/overview"
                                component={withThemeProviderWrapper(OverviewPage, webScanTheme)}
                            />
                        )}

                        <Route path="/" exact component={withThemeProviderWrapper(GenericScanPage, webScanTheme)} />
                    </>
                ) : (
                    <>
                        {!IS_MX_APP && (
                            <Route
                                exact
                                path="/bandwidth"
                                component={withThemeProviderWrapper(BandwidthCalculatorPage, webScanTheme)}
                            />
                        )}
                        <Route path="/" exact component={withThemeProviderWrapper(EmptyPage, webScanTheme)} />
                    </>
                )}
            </Suspense>
        </Switch>
    );
};

export default withRouter(Routes);
