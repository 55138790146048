import * as React from 'react';

export const FitnessBands = () => (
    <svg height="100%" width="100%" viewBox="0 0 78 130" fill="none" role="img" aria-label="fitness band">
        <path
            d="M60.937 50.929c0 .855-.701 1.56-1.552 1.56l-13.715.226h-.03a1.604 1.604 0 01-1.358-.796l-4.297-7.745-10.253 22.77c-.224.51-.716.84-1.284.916h-.164a1.547 1.547 0 01-1.298-.75L17.271 51.8h-6.09c-.85 0-1.551-.706-1.551-1.562 0-.855.701-1.56 1.552-1.56h6.85c.537 0 1.044.27 1.328.75l8.626 13.614 10.357-22.995c.239-.54.761-.901 1.358-.916h.06c.567 0 1.089.315 1.358.795l5.551 9.622 12.7-.196c.866.016 1.567.721 1.567 1.577zM56.952 70.951l-.015-.015a6.904 6.904 0 00-4.746-1.906c-1.537 0-3.06.51-4.328 1.546l-.268.21-.27-.24a6.895 6.895 0 00-4.73-1.906c-1.82 0-3.656.72-5.014 2.161a6.978 6.978 0 00-1.896 4.758c0 1.831.732 3.678 2.15 5.029v.015l8.387 8.435c.283.285.671.45 1.104.465h.015c.418-.015.806-.18 1.104-.465l8.507-8.556a6.8 6.8 0 001.97-4.773 6.816 6.816 0 00-1.97-4.758zm-2.18 7.295v.015l-7.416 7.46-7.298-7.325a3.807 3.807 0 01-1.104-2.687c0-.975.373-1.951 1.104-2.702a3.823 3.823 0 012.686-1.11c.956 0 1.925.375 2.672 1.11l1.104 1.111c.313.315.701.45 1.104.45.388 0 .791-.15 1.105-.45l.97-.99h.015a3.603 3.603 0 012.537-1.021c.91 0 1.82.345 2.537 1.02a3.6 3.6 0 01-.015 5.119z"
            fill="#000"
        />
        <path
            d="M71.966 53.885H69.55V35.513c0-3.317-2.672-6.004-5.97-6.004h-8.864V4.503c0-1.366-.702-2.552-1.672-3.302C52.058.42 50.79 0 49.432 0H19.629c-1.373 0-2.626.42-3.611 1.2-.97.751-1.687 1.937-1.687 3.303v24.976H6.138c-3.298 0-5.97 2.687-5.97 6.004v57.773c0 3.302 2.672 6.004 5.97 6.004l8.193.03v26.072c0 1.366.687 2.582 1.657 3.377.985.796 2.253 1.261 3.641 1.261h29.803c1.373 0 2.656-.465 3.626-1.261.97-.795 1.657-2.011 1.657-3.377V99.29h8.85c3.297 0 5.969-2.702 5.969-6.004V74.914h2.417c3.254 0 5.88-2.642 5.88-5.899V59.8c-.044-3.242-2.641-5.869-5.865-5.914zM17.316 4.503c0-.3.15-.615.552-.946.388-.315 1.03-.57 1.761-.555h29.803c.731-.015 1.358.24 1.76.555.404.33.538.646.538.946v25.006l-34.414-.03V4.503zM51.73 125.362c0 .36-.164.705-.567 1.051-.388.33-1.015.585-1.731.585H19.629c-.716 0-1.343-.255-1.746-.585-.403-.346-.567-.691-.567-1.051V99.29H51.73v26.072zm14.82-32.106a2.98 2.98 0 01-2.956 3.017l-.03.015H6.138c-1.641 0-2.984-1.35-2.984-3.002V35.513a2.992 2.992 0 012.984-3.002h57.426a2.983 2.983 0 012.985 3.002v57.743zm8.312-24.24a2.903 2.903 0 01-2.896 2.896H69.55v-15.01h2.417a2.894 2.894 0 012.896 2.897v9.216z"
            fill="#000"
        />
    </svg>
);
