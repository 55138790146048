import * as React from 'react';

export const Television = () => (
    <svg height="100%" width="100%" viewBox="0 0 33 28" fill="none" role="img" aria-label="tv">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.234.33H3.75C2.096.33.992 1.715.992 3.448v15.02c0 1.733 1.104 3.235 2.759 3.235h13.241v3.466h-6.069c-.938 0-1.93.693-1.93 1.733 0 .347.22.578.55.578.332 0 .553-.232.553-.578 0-.347.496-.578.827-.578h12.69c.33 0 .883.231.883.578s.22.578.551.578c.331 0 .552-.232.552-.578 0-.982-1.048-1.733-1.986-1.733h-5.517v-3.466h12.138c1.655 0 2.758-1.502 2.758-3.235V3.449c0-1.733-1.103-3.12-2.758-3.12zm1.655 18.139c0 1.097-.607 2.08-1.655 2.08h-12.47c-.055-.058-.165-.058-.22-.058-.055 0-.166 0-.22.057H3.75c-1.048 0-1.655-.982-1.655-2.08V3.449c0-1.097.827-1.963 1.93-1.963h25.987c1.048 0 1.876.866 1.876 1.964v15.02zM5.13 6.973L8 4.026a.64.64 0 00-.056-.808.517.517 0 00-.772 0l-2.87 2.946a.577.577 0 00.387.982c.11 0 .33-.058.441-.173zM11.475 4.2l-6.62 6.874a.577.577 0 00.385.982c.11 0 .276-.057.387-.173l6.62-6.875a.577.577 0 000-.808.517.517 0 00-.772 0z"
            fill="#000"
        />
    </svg>
);
